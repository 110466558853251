import { TeamRole } from 'lib/teams';
import dataV4 from '../data/plansV4.json';

export type Plan = {
  name: string;
  reference: keyof typeof PlanCodes;
  description: string;
  enableInvites: boolean;
  creationByContact: boolean;
  requiresPaymentMethod: boolean;
  displayPrice: boolean;
  available: boolean;
};

// Just plucked this out until we no longer need it
// The backend should be able to return all this.
interface StringMap {
  [key: string]: string;
}
// adding legacy here can get us into double legacy
const planNames: StringMap = {
  free: 'Free',
  normal: 'Studio',
  premium: 'Org',
  custom: 'Custom',
  custom_studio: 'Custom',
  pro: 'Pro',
  team: 'Voyager',
  enterprise: 'Enterprise',
};

export enum PlanCodes {
  free = 'free',
  normal = 'normal',
  pro = 'pro',
  team = 'team',
  enterprise = 'enterprise',
}

export enum BillingCycle {
  Annually = 'yearly',
  Yearly = 'yearly',
  Monthly = 'monthly',
  // NOTE: we are just making this available as as valid cycle from the backend
  // we are not giving users the ability to chose this.
  Quarterly = 'quarterly',
}

export type PlanCodeOptions =
  | PlanCodes.free
  | PlanCodes.pro
  | PlanCodes.team
  | PlanCodes.normal;
export type BillingCycleOptions = BillingCycle.Monthly | BillingCycle.Yearly;

export type UserRole = {
  email: string;
  permission: TeamRole;
};

export const getPlansV3 = (plan: String | undefined = undefined) => {
  const plans = dataV4.plans as Plan[];
  const codes = new Set();

  codes.add(PlanCodes.team);
  codes.add(PlanCodes.enterprise);
  const output = plans.filter((plan) => codes.has(PlanCodes[plan.reference]));

  if (plan && !codes.has(plan)) {
    const unavailablePlan = plans.find((_plan) => _plan.reference === plan);
    if (unavailablePlan) {
      output.unshift(unavailablePlan);
    }
  }

  return output;
};

export const getTeamPlans = () => {
  const plans = dataV4.plans as Plan[];
  const codes = new Set([PlanCodes.free, PlanCodes.team]);
  return plans.filter((plan) => codes.has(PlanCodes[plan.reference]));
};

export const getPlans = (codes: Set<PlanCodes>) => {
  const plans = dataV4.plans as Plan[];
  return plans.filter((plan) => {
    return codes.has(PlanCodes[plan.reference]);
  });
};

export const getPlan = (planCode: String) => {
  const plans = getPlansV3();
  return plans.find((plan) => plan.reference === planCode.toLowerCase());
};

// this is just hardcoded
export const planNameFromReference = (reference: string | undefined | null) => {
  if (reference !== undefined && reference !== null) {
    return planNames[reference.toLowerCase()];
  }
  return undefined;
};
