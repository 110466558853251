import featureFlags from 'lib/featureFlags';
import { checkIsMobile } from './deviceUtils';
import { PlanCodes } from './plans';

export function capitalize(s: string): string {
  if (s?.length < 1) return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const editorBaseUrl =
  process.env.NEXT_PUBLIC_EDITOR ?? 'https://editor.rive.app';
export const mobileSignupRedirectUrl =
  process.env.NEXT_PUBLIC_MOBILE_SIGNUP_REDIRECT_URL ?? '/learn-rive';

export const runtimeOptions = {
  canvas: 'canvas',
  'rive-renderer': 'webgl2',
};

export type runtimeOptionTypes =
  typeof runtimeOptions[keyof typeof runtimeOptions];

const _getEditorUrl = (
  pathname: string,
  searchParams: { [key: string]: string } = {}
): string => {
  const url = new URL(editorBaseUrl);
  url.pathname = pathname;

  Object.entries(searchParams).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });
  return url.href;
};

export const getNewSignupUrl = (method: string): string => {
  if (featureFlags.mobileSignupRedirect() && checkIsMobile()) {
    return mobileSignupRedirectUrl;
  }
  if (featureFlags.createFileOnSignup()) {
    return _getEditorUrl('/new_signup', { m: method });
  }
  return `/welcome?m=${method}`;
};

export const relativeTime = (secondsSinceEpoch: number): string => {
  // could use moment, but its 19kb
  const now = new Date().getTime() / 1000;
  const elapsedTime = now - secondsSinceEpoch;
  if (elapsedTime < 60) {
    // within minute
    return 'Just now';
  } else if (elapsedTime < 60 * 60) {
    // within hour
    const minutes = Math.round(elapsedTime / 60);
    return `${minutes}m`;
  } else if (elapsedTime < 60 * 60 * 24) {
    // within day
    const hours = Math.round(elapsedTime / (60 * 60));
    return `${hours}h`;
  } else {
    // else
    const days = Math.round(elapsedTime / (60 * 60 * 24));
    return `${days}d`;
  }
};

export function displayPrice(cents: number): string {
  return `$${(cents / 100).toFixed(2)}`;
}

export const pluralize = (label: string, count: number): string =>
  count > 1 ? `${label}s` : label;

export const isLegacy = (planCode: String | null) => {
  if (planCode === PlanCodes.normal) {
    return true;
  }
  if (planCode === PlanCodes.pro) {
    return true;
  }
  return false;
};

export const shouldAllowPlanChange = (
  from: String | null,
  to: String | null
) => {
  // we allow changing within pro
  if (from === PlanCodes.pro && to === PlanCodes.pro) return true;
  // any other legacy change is forbidden
  if (isLegacy(from) || isLegacy(to)) return false;
  // otherwise we are good
  return true;
};

export const getRenderer = (path: String): runtimeOptionTypes => {
  // const router = useRouter();
  // const path = router.asPath;
  const index = path.indexOf('?');
  if (index !== -1) {
    const query = path.substring(index);
    const params = new URLSearchParams(query);
    const runtime = params.get('runtime') || '';
    if (runtime in runtimeOptions) {
      return runtimeOptions[runtime as keyof typeof runtimeOptions];
    }
  }
  return runtimeOptions.canvas;
};
