export const COLORS = {
  white: '#f1f1f1',
  greyLightest: '#666666',
  greyLighter: '#444444',
  greyLight: '#323232',
  greyMid: '#282828',
  greyDark: '#242424',
  darker: '#1d1d1d',
  darkest: '#111111',

  magenta: '#FF5678',
  green: '#16E7B3',

  avatarColor1: '#4CBE9C',
  avatarColor2: '#FF5678',
  avatarColor3: '#CEAF3F',
  avatarColor4: '#C36AE2',
};

export const bypassRoutes = new Set(['/s/[id]/embed']);
