import { useMe } from './useMe';
import UserContext from './UserContext';

export class HttpError extends Error {
  constructor(public status: number, public error: object) {
    super('HttpError');
    this.status = status;
    this.error = error;
  }
}

const fetchUrlWithCookies = (url: string, options: RequestInit = {}) => {
  const { NEXT_PUBLIC_API: domain } = process.env;
  if (!domain) {
    throw new Error('Cannot call the API: env not set');
  }
  return fetch(`${domain}${url}`, {
    // `fetch` uses coookies with `credentials` set.
    credentials: 'include',
    ...options,
  });
};

const swrFetcher = (url: string) => {
  const { NEXT_PUBLIC_API: domain } = process.env;
  return fetch(`${domain}${url}`, {
    // `fetch` uses cookies with `credentials` set.
    credentials: 'include',
  }).then(async (swr) => {
    const contentType = swr.headers.get('Content-Type') || '';
    const responsePromise = contentType.includes('application/json')
      ? swr.json()
      : swr.text();
    const response = await responsePromise;

    if (!swr.ok) {
      throw new HttpError(swr.status, response);
    }
    return response;
  });
};

export { UserContext, useMe, fetchUrlWithCookies, swrFetcher };
