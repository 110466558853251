import React, { useEffect, useState } from 'react';
import {
  checkIsMobile,
  checkIsHeroTooWide,
  checkIsIFrame,
  checkIsNarrow,
} from 'lib/deviceUtils';

type State = {
  isMobile: boolean;
  isNarrow: boolean;
  isHeroTooWide: boolean;
  isAppMounted: boolean;
  isIFrame: boolean;
};

const IsMobileContext = React.createContext({
  isMobile: false,
  isNarrow: false,
  isHeroTooWide: false,
  isAppMounted: false,
  isIFrame: false,
});

export default IsMobileContext;

export function useIsMobile() {
  const [state, setState] = useState<State>({
    isMobile: false,
    isNarrow: false,
    isHeroTooWide: false,
    isAppMounted: false,
    isIFrame: false,
  });

  useEffect(() => {
    function handleResize() {
      setState({
        isMobile: checkIsMobile(),
        isNarrow: checkIsNarrow(),
        isHeroTooWide: checkIsHeroTooWide(),
        isIFrame: checkIsIFrame(),
        isAppMounted: true,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return state;
}
