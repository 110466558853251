import { RiveFile, useRiveFile } from '@rive-app/react-webgl2';
import { createContext } from 'react';

export type RiveFileProp = {
  riveFile: RiveFile;
};

type RiveFilesContextType = {
  openInRive: RiveFile | null;
  riveMark: RiveFile | null;
  reactions: RiveFile | null;
  popout: RiveFile | null;
};

// Eager loads rive files when the app starts.
// Only add rive files that are commonly used throughout the app and we are
// happy to load for every user when they go to rive-web
export function useRiveFiles(): RiveFilesContextType {
  const { riveFile: openInRive } = useRiveFile({
    src: '/static/open-in-rive.riv',
  });

  const { riveFile: riveMark } = useRiveFile({
    src: '/static/riv/rive-mark.riv',
  });

  const { riveFile: reactions } = useRiveFile({
    src: '/static/riv/reactions_v3.riv',
  });

  const { riveFile: popout } = useRiveFile({
    src: '/static/riv/popout.riv',
  });

  return { openInRive, riveMark, reactions, popout };
}

export const RiveFilesContext = createContext<RiveFilesContextType>({
  openInRive: null,
  riveMark: null,
  reactions: null,
  popout: null,
});
