import { setUserId } from 'lib/analytics';
import { MeResponse } from 'components/login/types';
import { useEffect, useRef, useState } from 'react';
import { fetchUrlWithCookies } from '.';
import { signedOut } from './UserContext';

// From https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
// Via https://stackoverflow.com/a/16427747
function isLocalStorageAvailable() {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export function useMe() {
  const [me, setMe] = useState<MeResponse | undefined>(undefined);
  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const isMounted = useRef<boolean>(true);

  async function fetchUser() {
    const res = await fetchUrlWithCookies('/api/me');
    const user: MeResponse = await res.json();

    if (!isMounted.current) {
      return;
    }

    setMe(user);
    if (user.signedIn) {
      setUserId(user.ownerId ?? null);
    } else {
      setUserId(null);
    }
    setUserLoaded(true);
  }

  function getCachedUserDetails() {
    if (typeof window === 'undefined' || !isLocalStorageAvailable()) {
      return;
    }

    const signedIn = localStorage.getItem('signedIn') === '1';
    const avatar = localStorage.getItem('avatar');
    const username = localStorage.getItem('username');
    const name = localStorage.getItem('name');

    setMe({
      signedIn,
      avatar: avatar || undefined,
      username: username || undefined,
      name: name || undefined,
    });
  }

  function setCachedUserDetails(user: MeResponse) {
    if (!isLocalStorageAvailable()) {
      return;
    }

    if (user.signedIn) {
      localStorage.setItem('signedIn', '1');
      localStorage.setItem('avatar', user.avatar ?? '');
      localStorage.setItem('username', user.username ?? '');
      localStorage.setItem('name', user.name ?? '');
    } else {
      localStorage.setItem('signedIn', '0');
      localStorage.removeItem('avatar');
      localStorage.removeItem('username');
      localStorage.removeItem('name');
    }
  }

  async function logout() {
    const response = await fetchUrlWithCookies('/api/signout', {
      method: 'POST',
    });

    if (!response.ok) {
      return;
    }

    if (isLocalStorageAvailable()) {
      localStorage.setItem('isSignedIn', '0');
    }
    setMe(signedOut);
    // Invalidate user response.
    window.location.assign('/login');
  }

  useEffect(() => {
    getCachedUserDetails();
    fetchUser();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const isSignedIn = me?.signedIn;

  useEffect(() => {
    if (isSignedIn !== undefined) {
      setCachedUserDetails(me!);
    }
  }, [isSignedIn, me]);

  return {
    user: me,
    setUser: setMe,
    userLoaded,
    reloadUser: fetchUser,
    logout,
  };
}
