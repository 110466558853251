import { ErrorResponse, PasswordError } from './types';
import { capitalize } from 'lib/utils';

export const checkPasswordForErrors = (password: string): PasswordError[] => {
  const errors: PasswordError[] = [];
  if (password.length < 10) {
    errors.push('too-short');
  }

  // Check for repeating characters
  for (let i = 0; i < password.length - 2; i++) {
    if (password[i] === password[i + 1] && password[i] === password[i + 2]) {
      errors.push('too-many-repeating-characters');
      break;
    }
  }

  if (!/(?=[a-z])/.test(password)) {
    errors.push('missing-lowercase');
  }
  if (!/(?=[A-Z])/.test(password)) {
    errors.push('missing-uppercase');
  }
  if (!/(?=[0-9])/.test(password)) {
    errors.push('missing-number');
  }
  if (!/(?=[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(password)) {
    errors.push('missing-special');
  }

  return errors;
};

export function getAPIErrorString(error: ErrorResponse): string | null {
  if (error.password) {
    if (error.password === 'too-long') {
      return 'Password can be at most 1024 characters';
    }
    if (error.password === 'too-short') {
      return 'Password must be at least 8 characters';
    }
    if (error.password === 'missing') {
      return 'Missing password';
    }
  }

  if (error.email) {
    if (error.email === 'invalid') {
      return 'Not a valid email';
    }
    if (error.email === 'in-use') {
      return 'Already registered';
    }
    if (error.email === 'missing') {
      return 'Missing email';
    }

    if (error.email === 'not-sso') {
      return 'Email not configured to use SSO. Click the link below to Log in with password, Google or Facebook';
    }
  }

  if (error.username) {
    if (error.username === 'bad-characters') {
      return 'Only alphanumeric and . or - are allowed';
    }
    if (error.username === 'too-short') {
      return 'Username is too short';
    }
    if (error.username === 'in-use') {
      return 'Username not available';
    }
    if (error.username === 'missing') {
      return 'Missing username';
    }
  }

  if (error.error) {
    if (error.error === 'invalid-credentials') {
      return 'No email and password combination exists for the credentials provided. Login via Google or Facebook if you signed up that way, otherwise use the "Forgot Password" link below to reset your password.';
    }
    return capitalize(error.error);
  }
  return null;
}

export function isNotEmpty<T extends Object>(error: T) {
  if (Object.keys(error).length === 1 && Object.hasOwn(error, 'password')) {
    return false;
  }

  if (Object.keys(error).length > 0) {
    return true;
  }

  return false;
}
