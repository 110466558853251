import { useState, useEffect } from 'react';

export function checkIsMobile() {
  return window.innerWidth < 800 || window.innerHeight <= 500;
}

export function checkIsNarrow() {
  return window.innerWidth < 1024;
}

export function checkIsHeroTooWide() {
  const aspectRatio = window.innerWidth / window.innerHeight;
  return aspectRatio >= 9 / 4;
}

export function checkIsIFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function useWindowSize() {
  type Size = {
    width: number;
    height: number;
  };

  const [windowSize, setWindowSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);
  return windowSize;
}
