import { useEffect, useRef } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import { useMe, UserContext } from 'contexts/user';
import { useIsMobile, IsMobileContext } from 'contexts/IsMobile';
import { RiveFilesContext, useRiveFiles } from 'contexts/riveFile';
import { init as initSentry } from 'lib/sentry';
import { pageview } from 'lib/analytics';
import { bypassRoutes } from 'lib/constants';

import 'styles/globals.css';

initSentry();

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  // We are bypassing our usual layout for our embed pages.
  // There are more sophisticaed ways of addressing this:
  // https://github.com/moxystudio/next-layout
  // https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
  if (bypassRoutes.has(router.route)) {
    return <Component />;
  }
  const previousRoute = useRef<string | undefined>(undefined);
  const preloadedRiveFiles = useRiveFiles();
  const me = useMe();
  const isMobileState = useIsMobile();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url, previousRoute.current);
      previousRoute.current = url;
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const url = router.asPath;
    pageview(url, undefined, router.pathname === '/404');
    previousRoute.current = url;
  }, []);

  return (
    <IsMobileContext.Provider value={isMobileState}>
      <UserContext.Provider value={me}>
        <RiveFilesContext.Provider value={preloadedRiveFiles}>
          <Component {...pageProps} />
        </RiveFilesContext.Provider>
      </UserContext.Provider>
    </IsMobileContext.Provider>
  );
}

export default MyApp;
