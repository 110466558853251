import { MeResponse } from 'components/login/types';
import { createContext } from 'react';
import { UserContextType } from './types';

export const signedOut: MeResponse = { signedIn: false };

const UserContext = createContext<UserContextType>({
  user: undefined,
  userLoaded: false,
  reloadUser: () => {},
});

export default UserContext;
