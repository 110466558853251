import { ErrorResponse } from 'components/login/types';
import { getAPIErrorString } from 'components/login/utils';

export const AMPLITUDE_TRACKING_ID = process.env
  .NEXT_PUBLIC_AMPLITUDE_API_KEY as string;

type logEventKeys = 'eventName' | 'parameters';
let readyForEvents = false;
const delayedEvents: Array<Record<logEventKeys, any>> = [];

/**
 * Mark our events to be ready, and flush any delayed events
 */
function makeReady() {
  // NOTE: we're not guaranteed to get consistent order here.
  readyForEvents = true;
  flushDelayedEvents();
}

/**
 * If there are any delayed events, log them.
 */
function flushDelayedEvents() {
  while (delayedEvents.length > 0) {
    const first = delayedEvents.pop();
    if (first) {
      const { eventName, parameters } = first;
      logEvent(eventName, parameters);
    }
  }
}

function logEvent(eventName: string, parameters: any) {
  if (window.amplitude) {
    if (readyForEvents) {
      const massagedEventName = massageEventName(eventName);
      window.amplitude.getInstance().logEvent(massagedEventName, parameters);
    } else {
      delayedEvents.push({ eventName, parameters });
    }
  }
}

function massageEventName(eventName: string) {
  const { NEXT_PUBLIC_SENTRY_ENV } = process.env;

  if (NEXT_PUBLIC_SENTRY_ENV) {
    return `${NEXT_PUBLIC_SENTRY_ENV}.rive_web.${eventName}`;
  } else {
    return `development.rive_web.${eventName}`;
  }
}

/**
 * Exported for testing, yuk
 */
export function clearReady() {
  readyForEvents = false;
  delayedEvents.splice(0, delayedEvents.length);
}

export const setUserId = (userId: number | null) => {
  // NOTE: we do not want to log, until we have confirmed if we are logged in or not
  if (window.amplitude) {
    if (userId) {
      window.amplitude.getInstance()?.setUserId(userId.toString());
    } else {
      window.amplitude.getInstance()?.setUserId(null);
    }
    makeReady();
  }
};

// NOTE: not sure how useful this event is considering the Google/Facebook flows do not
// trigger it currently. but it does force an event upon sign up
export const directSignUpCompleted = () =>
  logEvent('direct_sign_up_completed', {});

export const pageview = (
  url: URL | string,
  previousRoute?: string,
  is404: boolean = false
) =>
  logEvent('page_view', {
    url,
    ...(previousRoute && { previousRoute }),
    ...(is404 && { is_404: is404 }),
  });

export const communityDownload = (
  communityPostId: number,
  slug: string,
  title: string,
  fileId: number
) =>
  logEvent('community_download', {
    community_post_id: communityPostId,
    slug,
    title,
    file_id: fileId,
  });

export const communityShare = (
  communityPostId: number,
  slug: string,
  title: string,
  fileId: number,
  option: string
) =>
  logEvent('community_share', {
    community_post_id: communityPostId,
    slug,
    title,
    file_id: fileId,
    option,
  });

export const communityReport = (
  communityPostId: number,
  slug: string,
  title: string,
  fileId: number
) =>
  logEvent('community_report', {
    community_post_id: communityPostId,
    slug,
    title,
    file_id: fileId,
  });

export const communityOpenInRive = (
  communityPostId: number,
  slug: string,
  title: string,
  fileId: number
) =>
  logEvent('community_open_in_rive', {
    community_post_id: communityPostId,
    slug,
    title,
    file_id: fileId,
  });

export const siteAnimationOpenInRive = (title: string) =>
  logEvent('site_animation_open_in_rive', {
    title,
  });

export const logSignupError = (error: ErrorResponse) =>
  logEvent('signup_error', {
    email_error: error.email,
    password_error: error.password,
    displayed_error: getAPIErrorString(error),
  });

export const logLoginError = (error: ErrorResponse) =>
  logEvent('login_error', {
    email_error: error.email,
    password_error: error.password,
    displayed_error: getAPIErrorString(error),
  });

export const addCommunityReaction = (
  name: string,
  communityPostId: number,
  fileId: number,
  isTray: boolean
) =>
  logEvent('add_community_reaction', { name, communityPostId, fileId, isTray });

export const removeCommunityReaction = (
  name: string,
  communityPostId: number,
  fileId: number,
  isTray: boolean
) =>
  logEvent('remove_community_reaction', {
    name,
    communityPostId,
    fileId,
    isTray,
  });

export const commentsSendComment = (
  communityPostId: number,
  title: string,
  fileId: number
) =>
  logEvent('send_comment', {
    community_post_id: communityPostId,
    title,
    file_id: fileId,
  });

export const commentsSendReply = (
  commentId: number,
  communityPostId: number,
  title: string,
  fileId: number
) =>
  logEvent('send_reply', {
    comment_id: commentId,
    community_post_id: communityPostId,
    title,
    file_id: fileId,
  });

export const commentsAddReply = (
  commentId: number,
  communityPostId: number,
  title: string,
  fileId: number
) =>
  logEvent('add_reply', {
    comment_id: commentId,
    community_post_id: communityPostId,
    title,
    file_id: fileId,
  });

export const commentsCancelReply = (
  commentId: number,
  communityPostId: number,
  title: string,
  fileId: number
) =>
  logEvent('cancel_reply', {
    comment_id: commentId,
    community_post_id: communityPostId,
    title,
    file_id: fileId,
  });

export const commentsLogin = () => logEvent('login_from_comments', {});
export const commentsSignUp = () => logEvent('sign_up_from_comments', {});
export const commentsVerify = () => logEvent('verify_from_comments', {});

export const commentsShowReplies = (
  commentId: number,
  communityPostId: number,
  title: string,
  fileId: number
) =>
  logEvent('show_replies', {
    comment_id: commentId,
    community_post_id: communityPostId,
    title,
    file_id: fileId,
  });

export const commentsHideReplies = (
  commentId: number,
  communityPostId: number,
  title: string,
  fileId: number
) =>
  logEvent('hide_replies', {
    comment_id: commentId,
    community_post_id: communityPostId,
    title,
    file_id: fileId,
  });

export const copyShareLink = (fileId: number, shareLinkId: string) =>
  logEvent('copy_share_link', {
    file_id: fileId,
    share_link_id: shareLinkId,
  });

export const clickCommunityRemix = (
  communityPostId: number,
  fileId: number,
  title: string,
  isRemix: boolean,
  result:
    | 'learn_more_modal'
    | 'already_remixed_modal'
    | 'over_file_limit_modal'
    | 'redirect_to_login'
) =>
  logEvent('click_community_remix', {
    community_post_id: communityPostId,
    file_id: fileId,
    title,
    is_remix: isRemix,
    result,
  });

export const createCommunityRemix = (
  parentCommunityPostId: number,
  parentFileId: number,
  parentTitle: string,
  parentIsRemix: boolean,
  remixFileId: number,
  method: 'save_for_later' | 'start_editing' | 'remix_again'
) =>
  logEvent('create_community_remix', {
    parent_community_post_id: parentCommunityPostId,
    parent_file_id: parentFileId,
    parent_title: parentTitle,
    parent_is_remix: parentIsRemix,
    remix_file_id: remixFileId,
    method,
  });
