export default {
  // plays nicer with mocks
  createFileOnSignup: () =>
    process.env.NEXT_PUBLIC_CREATE_FILE_ON_SIGNUP === 'true',
  mobileSignupRedirect: () =>
    process.env.NEXT_PUBLIC_MOBILE_SIGNUP_REDIRECT === 'true',
  userLikes: () => process.env.NEXT_PUBLIC_USER_LIKES === 'true',
  communitySearch: () => process.env.NEXT_PUBLIC_COMMUNITY_SEARCH === 'true',
  ssoLogin: () => process.env.NEXT_PUBLIC_ENABLE_SSO_LOGIN === 'true',
  showSeatUse: () => process.env.NEXT_PUBLIC_SHOW_SEAT_USE === 'true',
  showRivOnHover: () => process.env.NEXT_PUBLIC_SHOW_RIV_ON_HOVER === 'true',
  fullscreenMode: () => process.env.NEXT_PUBLIC_FULLSCREEN_MODE === 'true',
  showRetiredStudioInfo: () =>
    process.env.NEXT_PUBLIC_SHOW_RETIRED_STUDIO_INFO === 'true',
  passwordComplexity: () =>
    process.env.NEXT_PUBLIC_PASSWORD_COMPLEXITY === 'true',
  suppressOpenInRive: () =>
    process.env.NEXT_PUBLIC_SUPPRESS_OPEN_IN_RIVE === 'true',
  getCommunityPath: () =>
    process.env.NEXT_PUBLIC_COMMUNITY_PATH ?? 'https://community.rive.app/',
};
